
import RegulationText from "../components/RegulationText.vue";
import { defineComponent, ref, inject, Ref } from "vue";
import { useStore } from "../store";
import { AxiosStatic } from "axios";
import { useRouter, useRoute } from "vue-router";
import { useI18n } from "vue-i18n";

export const AgreementsComponent = defineComponent({
  components: { RegulationText },
  setup() {
    const checked: Ref<boolean> = ref(false);
    const processingToTheNextPage: Ref<boolean> = ref(false);
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();
    const http = inject("http") as AxiosStatic;

    const acceptAgreements = async () => {
      processingToTheNextPage.value = true;
      await http.put(`patient-portal/api/agreements`);
      processingToTheNextPage.value = false;
      store.commit("updateUser", { agreements: true });
      router.push(route.query.redirectedFrom?.toString() ?? "");
    };

    return {
      checked,
      processingToTheNextPage,
      acceptAgreements,
      t,
    };
  },
});

export default AgreementsComponent;
